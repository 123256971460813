/* src/components/SecureForm.css */
.container {

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f8f8;
}

.form {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 600px;
    width: 100%;
}

.lock-icon {
    font-size: 48px;
    margin-bottom: 10px;
    color: green;
}

h2 {
    margin-bottom: 20px;
}

.activated {
    color: green;
}

.pending-activation {
    color: darkgoldenrod;
}

.form-group {
    margin-bottom: 15px;
    text-align: left;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

textarea {
    resize: vertical; /* Allows vertical resizing */
}

p {
    color: darkred;
    font-size: 14px;
}

.info-text {
    font-size: 14px;
    color: #555;
    margin-top: 10px;
    line-height: 1.5;
}
button {
    background-color: #6c63ff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}

button:hover {
    background-color: #574bff;
}

/* Responsive Design */
@media (max-width: 400px) {
    .form {
        padding: 15px;
    }
}
